<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('fund.fabu')"
        left-arrow
        @click-left="$router.go(-1)"
      >
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.nomore')"
        @load="onLoad"
        v-if="list.length"
      >
        <template #default>
          <ul>
            <van-row
              v-for="item in list"
              :key="item.id"
              @click="todetail(item.id)"
            >
              <div class="currency-Increase">
                <van-row>
                  <van-col :span="5" class="details-button">
                    <div class="dot">
                      <b>契</b>
                    </div>
                  </van-col>
                  <van-col :span="12" class="details-name">
                    <h3
                      style="font-size: 16px; display: flex; align-items: center;"
                    >
                      {{ item.title }}
                    </h3>
                  </van-col>
                  <van-col :span="7" class="details-button ">
                    <van-button>
                      {{ $t('fund.submit') }}
                    </van-button>
                  </van-col>
                </van-row>
                <van-row class="details-data">
                  <van-col :span="8">
                    <h4 style="color: rgb(0, 159, 128);">{{ item.fee }}%</h4>
                    <span>{{ $t('fund.profit') }}</span>
                  </van-col>
                  <van-col :span="8" style="text-align: center;">
                    <h4>{{ item.canuse }}</h4>
                    <span>{{ $t('fund.usable') }}</span>
                  </van-col>
                  <van-col :span="8" style="text-align: right;">
                    <h4>{{ item.product_type }}</h4>
                    <span>{{ $t('fund.biz') }}</span>
                  </van-col>
                </van-row>
              </div>
            </van-row>
          </ul>
        </template>
      </van-list>
      <van-empty v-else :description="$t('common.nomore')" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      list: [],
      finished: false
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/home/fund/infolist')
      if (data) {
        if (data.code === 200) {
          this.list = data.data
          this.finished = true
        }
      }
    },
    onLoad() {},
    // 发布详情页
    todetail(id) {
      this.$router.push('/fund/investment/detail/' + id)
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding: 60px 0 0 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .currency-Increase {
    background-color: #fff;
    padding: 0.8rem 0.53333rem;
    border-radius: 0.4rem;
    margin-bottom: 0.53333rem;
    .details-button {
      height: 3.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      .dot {
        width: 2.13333rem;
        height: 2.13333rem;
        margin-right: 0.53333rem;
        border-radius: 1.06667rem;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 10%;
        z-index: 99;
        background-color: #004ea3;
        color: #fff;
        font-size: 1.06667rem;
      }
      .van-button {
        border: none;
        background-color: #004ea3 !important;
        height: 2.13333rem;
        border-radius: 0.4rem;
        span {
          color: #fff !important;
        }
      }
    }
    .details-name {
      height: 3.2rem;
      text-align: left;
      line-height: 1.6rem;
      display: flex;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .details-data {
      margin-top: 0.26667rem;
      padding: 0.8rem 1.06667rem 0;
      text-align: left;
      line-height: 1.6rem;
      border-top: 0.02667rem solid #dcdcdc;
      h4 {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    span {
      font-size: 0.74667rem;
      color: #929395;
    }
  }
}
</style>
